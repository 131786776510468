import { Menu } from './menu.model';

export const verticalMenuItems = [
    new Menu(1, 'Dashboard', '/', null, 'dashboard', null, false, 0),

    new Menu(2, 'Security', null, null, 'supervisor_account', null, true, 0),
    new Menu(3, 'Users', '/ManageClientEntityUser', null, 'person_outline', null, false, 2),
    new Menu(4, 'Change Password', '/changePassword', null, 'vpn_key', null, false, 2),

    new Menu(5, 'Definitions', null, null, 'view_headline', null, true, 0),
    new Menu(6, 'Unit Types', '/ManageUnitType', null, 'account_balance', null, false, 5),
    new Menu(7, 'Unit Categories', '/ManageCategory', null, 'category', null, false, 5),
    new Menu(8, 'Custome Replies', '/ManagePredefinedTicketReply', null, 'reply', null, false, 5),

    new Menu(9, 'Units', '/ManageCommunityUnit', null, 'business', null, false, 0),

    new Menu(10, 'Owners', '/ManageOwner', null, 'recent_actors', null, false, 0),

    new Menu(11, 'Services', '/ManageTicket', null, 'room_service', null, false, 0),

    new Menu(12, 'Registration Requests', '/ManageRegistrationRequest', null, 'how_to_reg', null, false, 0),

    
    new Menu(13, 'Broadcast', '/ManageBroadcast', null, 'contactless', null, false, 0),

    new Menu(14, 'Explore', '/ManageExplore', null, 'explore', null, false, 0),

    new Menu(15, 'Reports', null, null, 'show_chart', null, true, 0),
    new Menu(16, 'Action Log Report', '/actionlogreport', null, 'bar_chart', null, false, 15),
    new Menu(17, 'Gate Log Report', '/gateLogreport', null, 'insert_chart', null, false, 15),
    new Menu(18, 'Owner Report', '/OwnerReport', null, 'insert_chart', null, false, 15),
    new Menu(19, 'Import Data', '/ImportData', null, 'import_export', null, false, 0),

    new Menu(20, 'Resort Rules And Policy', '/ResortRulesAndPolicy', null, 'policy', null, false, 0),

    new Menu(21, 'Manage Invoice', '/ManageInvoice', null, 'article', null, false, 0),
    new Menu(22, 'Import Invoice', '/ImportInvoice', null, 'import_export', null, false, 0),
    new Menu(23, 'Invoice Item', '/ManageInvoiceItem', null, 'article', null, false, 0),
    new Menu(24, 'Invitation Rules And Policy', '/InvitationRulesAndPolicy', null, 'policy', null, false, 0),

    new Menu(25, 'Contact Us', '/ContactUs', null, 'contact_support', null, false, 0),


 
];

export const horizontalMenuItems = [
    new Menu(1, 'Dashboard', '/', null, 'dashboard', null, false, 0),

    new Menu(2, 'Security', null, null, 'supervisor_account', null, true, 0),
    new Menu(3, 'Users', '/ManageClientEntityUser', null, 'person_outline', null, false, 2),
    new Menu(4, 'Change Password', '/changePassword', null, 'vpn_key', null, false, 2),

    new Menu(5, 'Definitions', null, null, 'view_headline', null, true, 0),
    new Menu(6, 'Unit Types', '/ManageUnitType', null, 'account_balance', null, false, 5),
    new Menu(7, 'Unit Categories', '/ManageCategory', null, 'category', null, false, 5),
    new Menu(8, 'Custome Replies', '/ManagePredefinedTicketReply', null, 'reply', null, false, 5),

    new Menu(9, 'Units', '/ManageCommunityUnit', null, 'business', null, false, 0),

    new Menu(10, 'Owners', '/ManageOwner', null, 'recent_actors', null, false, 0),

    new Menu(11, 'Services', '/ManageTicket', null, 'room_service', null, false, 0),

    new Menu(12, 'Registration Requests', '/ManageRegistrationRequest', null, 'how_to_reg', null, false, 0),

    new Menu(13, 'Broadcast', '/ManageBroadcast', null, 'contactless', null, false, 0),

    new Menu(14, 'Explore', '/ManageExplore', null, 'explore', null, false, 0),

    new Menu(15, 'Reports', null, null, 'show_chart', null, true, 0),
    new Menu(16, 'Action Log Report', '/actionlogreport', null, 'bar_chart', null, false, 15),
    new Menu(17, 'Gate Log Report', '/gateLogreport', null, 'insert_chart', null, false, 15),
    new Menu(18, 'Owner Report', '/OwnerReport', null, 'insert_chart', null, false, 15),
    new Menu(19, 'Import Data', '/ImportData', null, 'import_export', null, false, 0),

    new Menu(20, 'Resort Rules And Policy', '/ResortRulesAndPolicy', null, 'policy', null, false, 0),
    new Menu(21, 'Manage Invoice', '/ManageInvoice', null, 'article', null, false, 0),
    new Menu(22, 'Import Invoice', '/ImportInvoice', null, 'import_export', null, false, 0),
    new Menu(23, 'Invoice Item', '/ManageInvoiceItem', null, 'article', null, false, 0),
    new Menu(24, 'Invitation Rules And Policy', '/InvitationRulesAndPolicy', null, 'policy', null, false, 0),
 
    new Menu(25, 'Contact Us', '/ContactUs', null, 'contact_support', null, false, 0),


];
